import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Drawer,
  TextField,
  Typography,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axiosInstance from '../Admin/axiosInstance';

const ParcelCollection = ({ isDrawerOpen, toggleDrawer }) => {
  const [firstName, setFirstName] = useState('');
  // const [secondName, setSecondName] = useState('');
  const [idNumber, setIdNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [parcelCode, setParcelCode] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [parcelData, setParcelData] = useState(null);
  const [searchOption, setSearchOption] = useState('code');
  const [searchValue, setSearchValue] = useState('');
  const [cashAmount, setCashAmount] = useState('');
  const [paymentMode, setPaymentMode] = useState('');
  const [webcamStream, setWebcamStream] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);

  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const handleSearchInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const fetchParcelData = async () => {
    let endpoint = '';
    setLoading(true);
    setError('');

    try {
      if (searchOption === 'code') {
        endpoint = `/parcel/status/${searchValue}`;
      } else if (searchOption === 'senderName') {
        endpoint = `/parcel/searchbyname/${searchValue}`;
      } else if (searchOption === 'phone') {
        endpoint = `/parcel/searchbyphone/${searchValue}`;
      }

      const response = await axiosInstance.get(endpoint);
      if (response.data) {
        setParcelData(response.data);

        // Populate receiver details
        setFirstName(response.data.receiverName || '');
        setPhoneNumber(response.data.receiverPhone || '');
        setCashAmount(response.data.cashAmount || '');
      } else {
        setError('No parcel found.');
      }
    } catch (err) {
      console.error('Error fetching parcel data:', err);
      setError('Error fetching parcel data.');
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async () => {
    if (!parcelData) {
      setError('Please search and select a valid parcel.');
      return;
    }
  
    const formData = new FormData();
    formData.append('firstName', firstName);
    formData.append('idNumber', idNumber);
    formData.append('phoneNumber', phoneNumber);
  
    if (selectedFile) {
      formData.append('file', selectedFile);
    }
  
    // Add payment data if the parcel is not paid
    if (!parcelData.paid) {
      formData.append('cashAmount', cashAmount);
      formData.append('paymentMode', paymentMode);
    }
  
    try {
      // Define the endpoint for /parcelreceiver/add
      const addReceiverEndpoint = `/parcelreceiver/add/${parcelData.id}`;
  
      if (!parcelData.paid) {
        // Send to both endpoints when the parcel is not paid
        const codEndpoint = `/parcel/cod/${parcelData.id}`;
  
        // Send the first request to /parcel/cod
        const codResponse = await axiosInstance.put(codEndpoint, formData);
  
        if (codResponse.data.error) {
          setError(codResponse.data.error);
          return;
        }
      }
  
      // Send the second request to /parcelreceiver/add regardless of the paid status
      const receiverResponse = await axiosInstance.post(addReceiverEndpoint, formData);
  
      if (receiverResponse.data.error) {
        setError(receiverResponse.data.error);
        return;
      }
  
      console.log('Form submitted:', receiverResponse.data);
  
      // Reset form fields and close the drawer
      setFirstName('');
      setIdNumber('');
      setPhoneNumber('');
      setSelectedFile(null);
      setCapturedImage(null);
      setParcelData(null);
      setCashAmount('');
      setPaymentMode('');
      setError('');
      toggleDrawer(false)();
    } catch (error) {
      console.error('Error:', error);
      setError('An unexpected error occurred.');
    }
  };
    
  
  // const handleSubmit = async () => {
  //   if (!parcelData) {
  //     setError('Please search and select a valid parcel.');
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append('firstName', firstName);
  //   // formData.append('secondName', secondName);
  //   formData.append('idNumber', idNumber);
  //   formData.append('phoneNumber', phoneNumber);

  //   if (selectedFile) {
  //     formData.append('file', selectedFile);
  //   }

  //   try {
  //     const response = await axiosInstance.post(`/parcelreceiver/add/${parcelData.id}`, formData);
  //     if (response.data.error) {
  //       setError(response.data.error);
  //       return;
  //     }

  //     console.log('Form submitted:', response.data);

  //     setFirstName('');
  //     setIdNumber('');
  //     setPhoneNumber('');
  //     setParcelCode('');
  //     setSelectedFile(null);
  //     setCapturedImage(null);
  //     setParcelData(null);
  //     setError('');
  //     toggleDrawer(false)();
  //   } catch (error) {
  //     console.error('Error:', error);
  //     setError('An unexpected error occurred.');
  //   }
  // };

  const startWebcam = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setWebcamStream(stream);
      videoRef.current.srcObject = stream;
    } catch (err) {
      console.error('Error accessing webcam:', err);
      setError('Error accessing webcam.');
    }
  };

  const captureImage = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    canvas.toBlob(blob => {
      const file = new File([blob], 'captured-image.png', { type: 'image/png' });
      setSelectedFile(file);
      setCapturedImage(URL.createObjectURL(file));
    });
  };

  const stopWebcam = () => {
    if (webcamStream) {
      const tracks = webcamStream.getTracks();
      tracks.forEach(track => track.stop());
      setWebcamStream(null);
    }
  };

  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
      <Box
        sx={{
          width: 300,
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">Parcel Collection</Typography>
          <IconButton onClick={toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
  
        <FormControl fullWidth>
          <InputLabel>Search By</InputLabel>
          <Select
            value={searchOption}
            onChange={(e) => setSearchOption(e.target.value)}
            label="Search By"
          >
            <MenuItem value="code">Parcel Tracking Code</MenuItem>
            <MenuItem value="senderName">Sender Name</MenuItem>
            <MenuItem value="phone">Phone Number</MenuItem>
          </Select>
        </FormControl>
  
        <TextField
          label={
            searchOption === 'code'
              ? 'Parcel Code'
              : searchOption === 'senderName'
              ? 'Sender Name'
              : 'Phone Number'
          }
          variant="outlined"
          value={searchValue}
          onChange={handleSearchInputChange}
          fullWidth
        />
  
        <Button
          variant="contained"
          color="primary"
          onClick={fetchParcelData}
          disabled={loading || !searchValue}
        >
          {loading ? <CircularProgress size={24} /> : 'Search Parcel'}
        </Button>
  
        {/* Check if parcelData is not null before accessing its properties */}
        {parcelData ? (
          <Typography variant="body2" color="textPrimary">
            Parcel Found, Code: {parcelData.trackingCode || parcelData.senderName}
          </Typography>
        ) : (
          searchValue && <Typography variant="body2" color="error">Parcel not found.</Typography>
        )}
        {error && (
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        )}
  
        <TextField
          label="Name"
          variant="outlined"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          fullWidth
        />
        <TextField
          label="ID Number"
          variant="outlined"
          value={idNumber}
          onChange={(e) => setIdNumber(e.target.value)}
          fullWidth
        />
        <TextField
          label="Phone Number"
          variant="outlined"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          fullWidth
        />
        {/* Show payment fields if 'paid' is false */}
        {!parcelData?.paid ? (
          <>
          <FormControl fullWidth>
              <InputLabel>Payment Mode</InputLabel>
              <Select
                value={paymentMode}
                onChange={(e) => setPaymentMode(e.target.value)}
              >
                <MenuItem value="cash">Cash</MenuItem>
                <MenuItem value="mpesa">Mpesa</MenuItem>
                <MenuItem value="mpesa+cash">Mpesa + Cash</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Amount"
              variant="outlined"
              value={cashAmount}
              onChange={(e) => setCashAmount(e.target.value)}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          </>
        ) : (
          parcelData && (
            <Typography variant="body2" color="textPrimary">
              This parcel is already paid.
            </Typography>
          )
        )}
  
        <Button
          variant="outlined"
          onClick={startWebcam}
        >
          Open Webcam
        </Button>
  
        {webcamStream && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <video
              ref={videoRef}
              autoPlay
              playsInline
              sx={{ width: '100%' }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={captureImage}
            >
              Capture Image
            </Button>
            <canvas
              ref={canvasRef}
              style={{ display: 'none' }}
            />
            {capturedImage && (
              <Typography variant="body2">
                <img src={capturedImage} alt="Captured" style={{ maxWidth: '100%' }} />
                <Button
                  variant="outlined"
                  onClick={() => setSelectedFile(null)}
                >
                  Remove Image
                </Button>
              </Typography>
            )}
            <Button
              variant="outlined"
              color="secondary"
              onClick={stopWebcam}
            >
              Close Webcam
            </Button>
          </Box>
        )}
  
        <Button variant="outlined" component="label">
          Upload File
          <input type="file" hidden onChange={handleFileUpload} />
        </Button>
  
        {selectedFile && (
          <Typography variant="body2">Selected File: {selectedFile.name}</Typography>
        )}
  
        <Button variant="contained" color="success" onClick={handleSubmit} fullWidth>
          Submit
        </Button>
      </Box>
    </Drawer>
  );  
  
};

export default ParcelCollection;
