import React, { useState } from 'react';
import axiosInstance from '../Admin/axiosInstance'; 
import { TextField, Button, Container, Typography, Grid, Paper } from '@mui/material';
import { styled } from '@mui/system'; // Import styled for styling

const ContainerStyled = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'calc(100vh - 64px)', // Adjust height considering navbar height
  overflowY: 'auto', // Enable vertical scrolling
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const PaperStyled = styled(Paper)(({ theme }) => ({
  padding: '2rem',
  marginTop: '2rem',
  width: '100%',
  maxWidth: '600px',
  maxHeight: '70vh', // Limit height for scrolling
  overflowY: 'auto', // Enable scrolling for the form
}));

const ParcelStatus = () => {
  const [trackingCode, setTrackingCode] = useState('');
  const [parcelDetails, setParcelDetails] = useState(null);
  const [error, setError] = useState('');
  const [updatedDetails, setUpdatedDetails] = useState({}); // For holding updated details

  const handleInputChange = (event) => {
    setTrackingCode(event.target.value);
  };

  const handleCheckParcel = async () => {
    try {
      const response = await axiosInstance.get(`/parcel/status/${trackingCode}`); 
      setParcelDetails(response.data); 
      setError('');
      setUpdatedDetails(response.data); 
    } catch (error) {
      console.error('Error fetching parcel details:', error);
      setError('Failed to fetch parcel details. Please try again.');
    }
  };

  const handleUpdateChange = (event) => {
    const { name, value } = event.target;
    setUpdatedDetails(prevDetails => ({
      ...prevDetails,
      [name]: value, 
    }));
  };

  const handleUpdateParcel = async () => {
    try {
      await axiosInstance.put(`/parcel/update/${updatedDetails.id}`, updatedDetails); 
      alert('Parcel updated successfully');
      setParcelDetails(updatedDetails); 
    } catch (error) {
      console.error('Error updating parcel:', error);
      alert('Failed to update parcel. Please try again.');
    }
  };

   // Format number as currency
   const formatCurrency = (value) => {
    return value ? parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2 }) : '';
  };

  return (
    <ContainerStyled>
      <Typography variant="h4" gutterBottom>
        Check Parcel Details
      </Typography>
      {parcelDetails === null && ( // Show search input only if parcelDetails is null
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
          <TextField
            label="Tracking Code"
            variant="outlined"
            size="small"
            value={trackingCode}
            onChange={handleInputChange}
            style={{ marginRight: '1rem' }}
          />
          <Button variant="contained" color="primary" onClick={handleCheckParcel}>
            Check Parcel
          </Button>
        </div>
      )}
      {error && (
        <Typography variant="body1" color="error" style={{ marginTop: '1rem' }}>
          {error}
        </Typography>
      )}
      {parcelDetails && (
        <PaperStyled>
          <Typography variant="h6" gutterBottom>
            Update Parcel Information
          </Typography>
          <form style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Sender Name"
                  name="senderName"
                  variant="outlined"
                  size="small"
                  value={updatedDetails.senderName || ''}
                  onChange={handleUpdateChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Receiver Name"
                  name="receiverName"
                  variant="outlined"
                  size="small"
                  value={updatedDetails.receiverName || ''}
                  onChange={handleUpdateChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Sender Phone"
                  name="senderPhone"
                  variant="outlined"
                  size="small"
                  type="tel"
                  value={updatedDetails.senderPhone || ''}
                  onChange={handleUpdateChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Receiver Phone"
                  name="receiverPhone"
                  variant="outlined"
                  size="small"
                  type="tel"
                  value={updatedDetails.receiverPhone || ''}
                  onChange={handleUpdateChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Parcel Weight (kg)"
                  name="parcelWeight"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={updatedDetails.parcelWeight || ''}
                  onChange={handleUpdateChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Items Count"
                  name="itemsCount"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={updatedDetails.itemsCount || ''}
                  onChange={handleUpdateChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Package Type"
                  name="packageType"
                  variant="outlined"
                  size="small"
                  value={updatedDetails.packageType || ''}
                  onChange={handleUpdateChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Parcel Content"
                  name="parcelContent"
                  variant="outlined"
                  size="small"
                  value={updatedDetails.parcelContent || ''}
                  onChange={handleUpdateChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Cash Amount"
                  name="cashAmount"
                  variant="outlined"
                  size="small"
                  type="text"
                  value={formatCurrency(updatedDetails.cashAmount) || ''}
                  onChange={(e) => handleUpdateChange({ target: { name: 'cashAmount', value: e.target.value.replace(/[^\d.-]/g, '') } })} 
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="M-Pesa Amount"
                  name="mpesaAmount"
                  variant="outlined"
                  size="small"
                  type="text"
                  value={formatCurrency(updatedDetails.mpesaAmount) || ''}
                  onChange={(e) => handleUpdateChange({ target: { name: 'mpesaAmount', value: e.target.value.replace(/[^\d.-]/g, '') } })} // Allow only numbers and dot
                  fullWidth
                />
              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  label="Corporate Name"
                  name="corporateName"
                  variant="outlined"
                  size="small"
                  value={updatedDetails.corporateName || ''}
                  onChange={handleUpdateChange}
                  fullWidth
                />
              </Grid> */}
              {/* Add other fields as necessary based on parcelDetails response */}
            </Grid>
            <Button variant="contained" color="primary" onClick={handleUpdateParcel} style={{ marginTop: '1rem' }}>
              Update Parcel
            </Button>
          </form>
        </PaperStyled>
      )}
    </ContainerStyled>
  );
};

export default ParcelStatus;
