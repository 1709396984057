import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, Grid, Paper } from '@mui/material';
import { MonetizationOn, LocalShipping, DirectionsBus, AttachMoney } from '@mui/icons-material';
import { Line } from 'react-chartjs-2';
import axiosInstance from './axiosInstance';

const DashboardPage = () => {
  const [financialData, setFinancialData] = useState({
    revenue: 0,
    parcelRevenue: 0,
    busRevenue: 0,
    lorryRevenue: 0,
    busExpenses: 0,
    lorryExpenses: 0,
    systemCommission: 0,
    undespatched: 0,
    totalExpenses: 0,
    profitOrLoss: 0
  });
  const [loading, setLoading] = useState(false);
  const [monthlyData, setMonthlyData] = useState([]);

  useEffect(() => {
    const fetchFinancialData = async () => {
      setLoading(true);
      const endDate = new Date().toISOString().split('T')[0];
      const startDate = new Date(new Date().setMonth(new Date().getMonth() - 6))
        .toISOString().split('T')[0];
      
      try {
        const response = await axiosInstance.get(`/financials/totals/${startDate}/${endDate}`);
        const data = response.data;

        let totalParcelRevenue = 0;
        let totalBusRevenue = 0;
        let totalLorryRevenue = 0;
        let totalBusExpenses = 0;
        let totalLorryExpenses = 0;
        let totalSystemCommission = 0;
        let totalUndespatched = 0;
        let totalExpenses = 0;
        const monthlyTrend = [];

        Object.entries(data).forEach(([month, location]) => {
          monthlyTrend.push({
            month,
            parcelRevenue: location.parcel,
            busExpenses: location["bus Expenses"],
            lorryExpenses: location["lorry Expenses"],
            systemCommission: location["system commission"],
            undespatched: location.undespatched,
            busRevenue: location["bus collection"],
            lorryRevenue: location["lorry collection"]
          });

          totalParcelRevenue += location.parcel;
          totalBusRevenue += location["bus collection"];
          totalLorryRevenue += location["lorry collection"];
          totalBusExpenses += location["bus Expenses"];
          totalLorryExpenses += location["lorry Expenses"];
          totalSystemCommission += location["system commission"];
          totalUndespatched += location.undespatched;
          totalExpenses += location.expenses;
        });

        const totalRevenue = totalParcelRevenue;
        const profitOrLoss = totalRevenue - totalExpenses;

        setFinancialData({
          parcelRevenue: totalParcelRevenue.toFixed(2),
          busRevenue: totalBusRevenue.toFixed(2),
          lorryRevenue: totalLorryRevenue.toFixed(2),
          busExpenses: totalBusExpenses.toFixed(2),
          lorryExpenses: totalLorryExpenses.toFixed(2),
          systemCommission: totalSystemCommission.toFixed(2),
          undespatched: totalUndespatched.toFixed(2),
          totalExpenses: totalExpenses.toFixed(2),
          profitOrLoss: profitOrLoss.toFixed(2)
        });
        setMonthlyData(monthlyTrend);
      } catch (error) {
        console.error("Error fetching financial data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFinancialData();
  }, []);

  const lineChartData = {
    labels: monthlyData.map(item => item.month),
    datasets: [
      {
        label: 'Parcel Revenue',
        data: monthlyData.map(item => item.parcelRevenue),
        fill: true,
        backgroundColor: 'rgba(66, 165, 245, 0.2)',
        borderColor: '#42a5f5',
        pointBackgroundColor: '#42a5f5',
        pointBorderColor: '#fff',
        pointRadius: 5,
        tension: 0.4 // Smooth lines
      },
      {
        label: 'Bus Expenses',
        data: monthlyData.map(item => item.busExpenses),
        fill: true,
        backgroundColor: 'rgba(239, 83, 80, 0.2)',
        borderColor: '#ef5350',
        pointBackgroundColor: '#ef5350',
        pointBorderColor: '#fff',
        pointRadius: 5,
        tension: 0.4
      },
      {
        label: 'Lorry Expenses',
        data: monthlyData.map(item => item.lorryExpenses),
        fill: true,
        backgroundColor: 'rgba(255, 167, 38, 0.2)',
        borderColor: '#ffa726',
        pointBackgroundColor: '#ffa726',
        pointBorderColor: '#fff',
        pointRadius: 5,
        tension: 0.4
      },
      {
        label: 'System Commission',
        data: monthlyData.map(item => item.systemCommission),
        fill: true,
        backgroundColor: 'rgba(255, 202, 40, 0.2)',
        borderColor: '#ffca28',
        pointBackgroundColor: '#ffca28',
        pointBorderColor: '#fff',
        pointRadius: 5,
        tension: 0.4
      }
    ]
  };
  
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#333', // Text color for legend labels
          font: {
            size: 14,
            weight: 'bold'
          }
        }
      },
      title: {
        display: true,
        text: 'Monthly Financial Trends',
        color: '#333',
        font: {
          size: 20,
          weight: 'bold'
        }
      },
      tooltip: {
        enabled: true,
        backgroundColor: '#333',
        titleFont: { size: 14, weight: 'bold' },
        bodyFont: { size: 12 },
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: KSh ${context.raw.toLocaleString()}`;
          }
        }
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Month',
          color: '#333',
          font: { size: 16, weight: 'bold' }
        },
        grid: {
          display: false // Removes x-axis gridlines
        },
        ticks: {
          color: '#333',
          font: { size: 12 }
        }
      },
      y: {
        title: {
          display: true,
          text: 'Amount (KSh)',
          color: '#333',
          font: { size: 16, weight: 'bold' }
        },
        grid: {
          color: 'rgba(200, 200, 200, 0.3)' // Lighten the gridlines
        },
        ticks: {
          color: '#333',
          font: { size: 12 },
          callback: function(value) {
            return `KSh ${value.toLocaleString()}`; // Format y-axis labels with "KSh"
          }
        }
      }
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Financial Dashboard
      </Typography>
      <Box sx={{ marginTop: 4 }}>
        <Typography variant="h5" gutterBottom>Monthly Financial Trends</Typography>
        <Line data={lineChartData} />
      </Box>
      <Grid container spacing={4}>
        {/* <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ padding: 2, display: 'flex', alignItems: 'center', backgroundColor: '#66bb6a' }}>
            <MonetizationOn sx={{ color: 'white', fontSize: 40, marginRight: 2 }} />
            <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
              <Typography variant="h6" sx={{ color: 'white' }}>Total Revenue</Typography>
              <Typography variant="h4" sx={{ color: 'white' }}>
                {loading ? "Loading..." : `KSh ${financialData.revenue}`}
              </Typography>
            </Box>
          </Paper>
        </Grid> */}
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ padding: 2, display: 'flex', alignItems: 'center', backgroundColor: '#42a5f5' }}>
            <LocalShipping sx={{ color: 'white', fontSize: 40, marginRight: 2 }} />
            <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
              <Typography variant="h6" sx={{ color: 'white' }}>Total Revenue</Typography>
              <Typography variant="h4" sx={{ color: 'white' }}>
                {loading ? "Loading..." : `KSh ${financialData.parcelRevenue}`}
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{
            padding: 2,
            display: 'flex',
            alignItems: 'center',
            backgroundColor: financialData.profitOrLoss >= 0 ? '#66bb6a' : '#ef5350'
          }}>
            <AttachMoney sx={{ color: 'white', fontSize: 40, marginRight: 2 }} />
            <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
              <Typography variant="h6" sx={{ color: 'white' }}>Profit/Loss</Typography>
              <Typography variant="h4" sx={{ color: 'white' }}>
                {loading ? "Loading..." : `KSh ${financialData.profitOrLoss}`}
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ padding: 2, display: 'flex', alignItems: 'center', backgroundColor: '#ffa726' }}>
            <AttachMoney sx={{ color: 'white', fontSize: 40, marginRight: 2 }} />
            <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
              <Typography variant="h6" sx={{ color: 'white' }}>Lorry Revenue</Typography>
              <Typography variant="h4" sx={{ color: 'white' }}>
                {loading ? "Loading..." : `KSh ${financialData.lorryRevenue}`}
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ padding: 2, display: 'flex', alignItems: 'center', backgroundColor: '#ffa726' }}>
            <AttachMoney sx={{ color: 'white', fontSize: 40, marginRight: 2 }} />
            <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
              <Typography variant="h6" sx={{ color: 'white' }}>Bus Revenue</Typography>
              <Typography variant="h4" sx={{ color: 'white' }}>
                {loading ? "Loading..." : `KSh ${financialData.busRevenue}`}
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ padding: 2, display: 'flex', alignItems: 'center', backgroundColor: '#ef5350' }}>
            <DirectionsBus sx={{ color: 'white', fontSize: 40, marginRight: 2 }} />
            <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
              <Typography variant="h6" sx={{ color: 'white' }}>Bus Expenses</Typography>
              <Typography variant="h4" sx={{ color: 'white' }}>
                {loading ? "Loading..." : `KSh ${financialData.busExpenses}`}
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ padding: 2, display: 'flex', alignItems: 'center', backgroundColor: '#ffca28' }}>
            <AttachMoney sx={{ color: 'white', fontSize: 40, marginRight: 2 }} />
            <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
              <Typography variant="h6" sx={{ color: 'white' }}>System Commission</Typography>
              <Typography variant="h4" sx={{ color: 'white' }}>
                {loading ? "Loading..." : `KSh ${financialData.systemCommission}`}
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ padding: 2, display: 'flex', alignItems: 'center', backgroundColor: '#ffca28' }}>
            <AttachMoney sx={{ color: 'white', fontSize: 40, marginRight: 2 }} />
            <Box sx={{ textAlign: 'center', flexGrow: 1 }}>
              <Typography variant="h6" sx={{ color: 'white' }}>Total Expenses</Typography>
              <Typography variant="h4" sx={{ color: 'white' }}>
                {loading ? "Loading..." : `KSh ${financialData.totalExpenses}`}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardPage;
