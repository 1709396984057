import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Paper, Link } from '@mui/material';
import axios from 'axios';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import logoImage from '../images/dream.PNG';  

const LoginPage = ({ setToken, setUser }) => {
  const [credentials, setCredentials] = useState({ userName: '', password: '' });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/login`, credentials);
      const { access_token, userId, userName, role, location } = response.data;
  
      // Store the necessary data in state and localStorage
      setToken(access_token);
      setUser(userName);
      localStorage.setItem('token', access_token);
      localStorage.setItem('userId', userId);  // Store userId for later use
      localStorage.setItem('username', userName);
      localStorage.setItem('place', location);
      localStorage.setItem('role', role);
  
      // Redirect based on user role
      if (role === 'ADMIN') {
        navigate('/admin/dashboard');  // Redirect to admin page
      } else {
        navigate('/home');  // Redirect to home page
      }
    } catch (err) {
      setError('Invalid username or password');
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f0f0f0',  // Grey background for the entire screen
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          maxWidth: 400,
          width: '100%',
          backgroundColor: '#fff',  // White background for the form
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <img src={logoImage} alt="Logo" style={{ height: 70 }} /> {/* Logo image at the top */}
        </Box>
        <Typography variant="h5" align="center">Login</Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label="Username"
            name="userName"
            type="text"
            value={credentials.userName}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Password"
            name="password"
            type="password"
            value={credentials.password}
            onChange={handleChange}
            margin="normal"
            required
          />
          {error && <Typography color="error">{error}</Typography>}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
          >
            Login
          </Button>
          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <Link component={RouterLink} to="/reset-password" variant="body2">
              Forgot your password?
            </Link>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default LoginPage;
