import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Button, Typography, Box, TablePagination, Table, TableBody, TableCell, TableHead, TableRow,
  Select, MenuItem, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Alert, Modal
} from '@mui/material';
import axiosInstance from '../Admin/axiosInstance';

const ParcelList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [parcelListOpen, setParcelListOpen] = useState(false);
  const [parcelData, setParcelData] = useState([]);
  const [statusChanges, setStatusChanges] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [startDate, setStartDate] = useState('');
  const [destination, setDestination] = useState('');
  const [endDate, setEndDate] = useState('');
  const [direction, setDirection] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [collectedByData, setCollectedByData] = useState(null);
  const [isCollectedModalOpen, setIsCollectedModalOpen] = useState(false);
  const [error, setError] = useState(null);

  const statusFlow = ["INPROGRESS", "DISPATCHED", "DELIVERED", "COLLECTED", "RETURNED", "RETURNEDDELIVERED", "RETURNEDCOLLECTED"];

  // Check if user navigated back using location state
  useEffect(() => {
    if (location.state?.parcelData) {
      setParcelData(location.state.parcelData);
      setParcelListOpen(true);
    }
  }, [location.state]);

  // Custom styles
  const customButtonStyle = { marginLeft: 2 };
  const customTableHeadStyle = { backgroundColor: '#1976d2', color: 'white', fontWeight: 'bold', fontSize: '14px' };

  const handleFetchData = async () => {
    setDialogOpen(false);
    try {
      const response = await axiosInstance.get(`/parcel/list/${startDate}/${endDate}/${direction}`);
      setParcelData(response.data);
      alert('Data fetched successfully');
    } catch (error) {
      console.error('Error fetching parcel data:', error);
      alert('Failed to fetch data');
    }
  };

  const handleUpdateClick = async (parcelId) => {
    const newStatus = statusChanges[parcelId];
    const currentStatus = parcelData.find(parcel => parcel.id === parcelId).status;

    if (!newStatus) {
      alert('Please select a status before updating');
      return;
    }

    const currentStatusIndex = statusFlow.indexOf(currentStatus);
    const newStatusIndex = statusFlow.indexOf(newStatus);

    if (newStatusIndex <= currentStatusIndex) {
      alert('Cannot update parcel to the same or a previous status.');
      return;
    }

    try {
      const response = await axiosInstance.patch(`/parcel/${parcelId}`, { status: newStatus });
      if (response.status === 200) {
        alert('Parcel status updated successfully');
        const updatedParcels = await axiosInstance.get('/parcel/viewAll');
        setParcelData(updatedParcels.data);
      } else {
        alert('Failed to update parcel status');
      }
    } catch (error) {
      console.error('Error updating parcel status:', error);
      alert('An error occurred while updating parcel status');
    }
  };

  // Function to handle fetching collected by data
  const handleCollectedByClick = async (parcelId) => {
    try {
        const response = await axiosInstance.get(`/parcelreceiver/collectedBy/${parcelId}`);
        console.log(response.data); 
        if (response.data) {
            setCollectedByData(response.data); 
            setError(null);  
        } else {
            setCollectedByData(null);
            setError('The parcel has not yet been collected.');
        }
        setIsCollectedModalOpen(true);
    } catch (error) {
        setError('Error fetching collected by data');
        console.error(error);
    }
};


  const handleCloseModal = () => {
    setIsCollectedModalOpen(false);
  };

  const locations = [
    'Nairobi', 'Thika', 'Matuu', 'Kathiyoko', 'Mwingi', 'Nguni', 'Ukasi', 'Bisan hargeysa',
    'Bangal', 'Madogo', 'Garissa', 'Shimbirey', 'Dujis', 'Madogashe', 'Habaswein',
    'Qanchara', 'Lag boqol', 'Leheley', 'Wajir'
  ];

  const handleReprintClick = (parcel) => {
    navigate(`/reprint-receipt/${parcel.id}`, { state: { parcel, parcelData } });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleGeneratePDF = () => {
    if (parcelData.length === 0) {
      alert('No data available to generate the PDF. Please fetch data first.');
      return;
    }
    generatePDF(parcelData);
  };

  const generatePDF = (data) => {
    const doc = new jsPDF({ orientation: 'landscape' });

    // Add header
    doc.setFontSize(16);
    doc.text('Parcel Loading List', 14, 20);
    doc.setFontSize(12);
    doc.text('Dream C', 14, 28);
    doc.text('Headquarters: Nairobi, Kenya', 14, 36);
    doc.text('Tel Nairobi: 0769200300', 14, 44);
    doc.text('Tel Wajir: 0769200400', 14, 52);

    // Prepare the data for the table
    const tableColumn = [
      'Source',
      'Destination',
      'Sender Name',
      'Sender Phone',
      'Receiver Name',
      'Receiver Phone',
      'Book Date',
      'Parcel Weight',
      'Tracking Code',
      'Status',
    ];
    const tableRows = data.map(parcel => [
      parcel.source,
      parcel.destination,
      parcel.senderName,
      parcel.senderPhone,
      parcel.receiverName,
      parcel.receiverPhone,      
      parcel.bookDate,     
      parcel.parcelWeight,
      parcel.trackingCode,
      parcel.status,
    ]);

    // Add the table
    doc.autoTable({
      startY: 60,
      head: [tableColumn],
      body: tableRows,
    });

    // Save the PDF
    doc.save('Parcel_Loading_List.pdf');
  };

  return (
    <Box sx={{ padding: 3 }}>
      {/* Dialog for selecting start date, end date, and direction */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Select Parcel List Criteria</DialogTitle>
        <DialogContent>
          <TextField
            label="Start Date"
            type="date"
            fullWidth
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            sx={{ mb: 2 }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="End Date"
            type="date"
            fullWidth
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            sx={{ mb: 2 }}
            InputLabelProps={{ shrink: true }}
          />
          <Select
            fullWidth
            value={direction}
            onChange={(e) => setDirection(e.target.value)}
            displayEmpty
            sx={{ mb: 2 }}
          >
            <MenuItem value="" disabled>Select Route</MenuItem>
            <MenuItem value="1">Nairobi - Wajir</MenuItem>
            <MenuItem value="2">Wajir - Nairobi</MenuItem>
          </Select>
          {/* <Select
          label="Destination"
          fullWidth
          value={destination}
          onChange={(e) => setDestination(e.target.value)}
          displayEmpty
          sx={{ mb: 2 }}
        >
          <MenuItem value="" disabled>Select Destination</MenuItem>
          {locations.map((location, index) => (
            <MenuItem key={index} value={location}>
              {location}
            </MenuItem>
          ))}
        </Select> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="secondary">Cancel</Button>
          <Button onClick={handleFetchData} color="primary">Fetch Parcels</Button>
        </DialogActions>
      </Dialog>

      {/* Button to open the form dialog */}
      <Button variant="contained" onClick={() => setDialogOpen(true)} color="primary">Select Date and Route</Button>

      {/* Parcel Table */}
      {/* {parcelListOpen && ( */}
        <Box sx={{ mt: 3, width: '100%', overflowX: 'auto' }}>
          <Typography variant="h5">Parcel List</Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleGeneratePDF}
            sx={{
              mb: 2,
              backgroundColor: '#1E90FF', 
              ':hover': {
                backgroundColor: '#82CAFF', 
              },
            }}
          >
            Generate Parcel List PDF
          </Button>
          <Table sx={{ minWidth: 1200 }}>
            <TableHead>
              <TableRow>
                <TableCell sx={customTableHeadStyle}>Sender</TableCell>
                <TableCell sx={customTableHeadStyle}>Receiver</TableCell>
                <TableCell sx={customTableHeadStyle}>Origin</TableCell>
                <TableCell sx={customTableHeadStyle}>Destination</TableCell>
                <TableCell sx={customTableHeadStyle}>Received Date</TableCell>
                <TableCell sx={customTableHeadStyle}>Price</TableCell>
                <TableCell sx={customTableHeadStyle}>Parcel Weight</TableCell>
                <TableCell sx={customTableHeadStyle}>Tracking Code</TableCell>
                <TableCell sx={customTableHeadStyle}>Items No</TableCell>
                <TableCell sx={customTableHeadStyle}>Status</TableCell>
                <TableCell sx={customTableHeadStyle}>Action</TableCell>
                <TableCell sx={customTableHeadStyle}>Collected By</TableCell>
                <TableCell sx={customTableHeadStyle}>Reprint Receipt</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {parcelData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((parcel) => (
                <TableRow key={parcel.id}>
                  <TableCell>{parcel.senderName}</TableCell>
                  <TableCell>{parcel.receiverName}</TableCell>
                  <TableCell>{parcel.source}</TableCell>
                  <TableCell>{parcel.destination}</TableCell>
                  <TableCell>{parcel.bookDate}</TableCell>
                  <TableCell>{parcel.cashAmount + parcel.mpesaAmount}</TableCell>
                  <TableCell>{parcel.parcelWeight}</TableCell>
                  <TableCell>{parcel.trackingCode}</TableCell>
                  <TableCell>{parcel.itemsCount}</TableCell>
                  <TableCell>{parcel.status === 'INPROGRESS' ? 'RECEIVED' : parcel.status}</TableCell>
                  <TableCell>
                    <Select
                      value={statusChanges[parcel.id] || parcel.status}
                      onChange={(e) => setStatusChanges({ ...statusChanges, [parcel.id]: e.target.value })}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>Select Status</MenuItem>
                      {statusFlow.map(status => (
                        <MenuItem key={status} value={status}>{status}</MenuItem>
                      ))}
                    </Select>
                    <Button variant="contained" color="primary" onClick={() => handleUpdateClick(parcel.id)} sx={customButtonStyle}>Update</Button>
                  </TableCell>
                  <TableCell>
                    <Typography onClick={() => handleCollectedByClick(parcel.id)} sx={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}>Collected By</Typography>
                    </TableCell>
              <TableCell>
                <Typography onClick={() =>  handleReprintClick(parcel)} sx={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}>Reprint Receipt</Typography>
                {/* <Button variant="contained" color="secondary" onClick={() => handleReprintClick(parcel)}>Reprint</Button> */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={parcelData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

       {/* Collected By Modal */}
       <Modal
            open={isCollectedModalOpen}
            onClose={handleCloseModal}
            aria-labelledby="collected-by-modal-title"
            aria-describedby="collected-by-modal-description"
          >
            <Box 
              sx={{ 
                padding: 4, 
                backgroundColor: 'white', 
                margin: 'auto', 
                maxWidth: '600px', 
                borderRadius: '10px', 
                boxShadow: 24,
                border: '1px solid #ddd'
              }}
            >
              {error ? (
                <Alert severity="error">{error}</Alert>
              ) : (
                collectedByData && (
                  <>
                    <Typography id="collected-by-modal-title" variant="h6" sx={{ marginBottom: 2 }}>
                      Parcel Collection Details
                    </Typography>

                    <Grid container spacing={2}>
                      {/* Parcel Details */}
                      <Grid item xs={12}>
                        <Typography variant="h6" sx={{ marginTop: 2 }}>Parcel Details</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography fontWeight="bold">Tracking Number:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{collectedByData.parcel.trackingCode}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography fontWeight="bold">Origin:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{collectedByData.parcel.source}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography fontWeight="bold">Destination:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{collectedByData.parcel.destination}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography fontWeight="bold">Sender Name:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{collectedByData.parcel.senderName}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography fontWeight="bold">Item Count:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{collectedByData.parcel.itemsCount}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography fontWeight="bold">Parcel Content:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{collectedByData.parcel.parcelContent}</Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="h6" sx={{ marginTop: 2 }}>User collected Details</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography fontWeight="bold">Name:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{collectedByData.firstName}</Typography>
                      </Grid>

                      {/* <Grid item xs={6}>
                        <Typography fontWeight="bold">Last Name:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{collectedByData.lastName || 'N/A'}</Typography>
                      </Grid> */}

                      <Grid item xs={6}>
                        <Typography fontWeight="bold">Phone Number:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{collectedByData.phoneNumber}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography fontWeight="bold">ID Number:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{collectedByData.idNumber}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography fontWeight="bold">Date:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{collectedByData.date}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography fontWeight="bold">Time:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{collectedByData.time}</Typography>
                      </Grid>
                    </Grid>
                  </>
                )
              )}
            </Box>
          </Modal>
    </Box>
  {/* )} */}
</Box>
  );
};

export default ParcelList;
