import React, { useState } from 'react';
import axiosInstance from './axiosInstance';
import DataTable from 'react-data-table-component';
import { TextField, Button } from '@mui/material';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const LorryReports = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [financialData, setFinancialData] = useState({});
  const offices = [
    "Nairobi", 
    "Garissa", 
    "MWINGI",
    "Habaswein", 
    "Lag Boqol", 
    "Leheley", 
    "Wajir"
  ];

  const fetchFinancialData = async () => {
    try {
      const response = await axiosInstance.get(`/financials/totals/${startDate}/${endDate}`);
      setFinancialData(response.data);
    } catch (error) {
      console.error("Error fetching financial data", error);
    }
  };

  const calculateDeficitSurplus = (lorryCollection, lorryExpenses, lorryCommission, lorryVAT) => {
    lorryCollection = lorryCollection || 0;
    lorryExpenses = lorryExpenses || 0;
    lorryCommission = lorryCommission || 0;
    lorryVAT = lorryVAT || 0;
    return parseFloat(lorryCollection - lorryExpenses - lorryCommission - lorryVAT).toFixed(2);
  };

  const exportToExcel = () => {
    const excelData = offices.map(office => {
      const officeData = financialData[office] || { parcel: 0, reservation: 0, expenses: 0 };
      const deficitSurplus = calculateDeficitSurplus(
        officeData['lorry collection'] || 0, 
        officeData['lorry Expenses'] || 0, 
        officeData['lorry commission'] || 0,
        officeData['lorry VAT'] || 0
      );

      return {
        Office: office,
        Parcel: Number(officeData.parcel || 0).toFixed(2), 
        Tickets: Number(officeData.reservation || 0).toFixed(2), 
        Expenses: Number(officeData.expenses || 0).toFixed(2), 
        'System Commission': Number(officeData['system commission'] || 0).toFixed(2),
        'Deficit/Surplus': deficitSurplus 
      };
    });

    // Create a worksheet
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    
    // Create a workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Financial Report');
    
    // Write the Excel file and trigger download
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    
    saveAs(blob, 'financial_report.xlsx');
  };

  const columns = [
    {
      name: 'Metric',
      selector: row => row.metric,
      sortable: true,
    },
    ...offices.map(office => ({
      name: office,
      selector: row => {
        const value = row[office];
        return Number(value).toFixed(2);
      },
      sortable: true,
      conditionalCellStyles: [
        {
          when: row => row.metric === 'Deficit/Surplus' && row[office] > 0,
          style: {
            backgroundColor: 'green',
            color: 'white',
          },
        },
        {
          when: row => row.metric === 'Deficit/Surplus' && row[office] < 0,
          style: {
            backgroundColor: 'red',
            color: 'white',
          },
        },
      ],
    })),
    {
      name: 'Total',
      selector: row => {
        const value = row.total;
        return (typeof value === 'number' ? value : parseFloat(value)).toFixed(2);
      },
      sortable: true,
    }
  ];

  const metrics = [
    { 
      metric: 'Parcel (Total)', 
      total: offices.reduce((acc, office) => acc + (financialData[office]?.parcel || 0), 0).toFixed(2),
      ...offices.reduce((acc, office) => {
        const officeData = financialData[office] || { parcel: 0 };
        acc[office] = Number(officeData.parcel || 0).toFixed(2);
        return acc;
      }, {})
    },
    { 
      metric: 'Lorry Collection', 
      total: offices.reduce((acc, office) => acc + (financialData[office]?.['lorry collection'] || 0), 0).toFixed(2),
      ...offices.reduce((acc, office) => {
        const officeData = financialData[office] || { 'lorry collection': 0 };
        acc[office] = Number(officeData['lorry collection'] || 0).toFixed(2);
        return acc;
      }, {})
    },
    { 
      metric: 'Lorry Expenses', 
      total: offices.reduce((acc, office) => acc + (financialData[office]?.['lorry Expenses'] || 0), 0).toFixed(2),
      ...offices.reduce((acc, office) => {
        const officeData = financialData[office] || { 'lorry Expenses': 0 };
        acc[office] = Number(officeData['lorry Expenses'] || 0).toFixed(2);
        return acc;
      }, {})
    },
    { 
      metric: 'Lorry Commission', 
      total: offices.reduce((acc, office) => acc + (financialData[office]?.['lorry commission'] || 0), 0).toFixed(2),
      ...offices.reduce((acc, office) => {
        const officeData = financialData[office] || { 'lorry commission': 0 };
        acc[office] = Number(officeData['lorry commission'] || 0).toFixed(2);
        return acc;
      }, {})
    },
    { 
      metric: 'Lorry VAT', 
      total: offices.reduce((acc, office) => acc + (financialData[office]?.['lorry VAT'] || 0), 0).toFixed(2),
      ...offices.reduce((acc, office) => {
        const officeData = financialData[office] || { 'lorry VAT': 0 };
        acc[office] = Number(officeData['lorry VAT'] || 0).toFixed(2);
        return acc;
      }, {})
    },
    { 
      metric: 'Deficit/Surplus', 
      total: offices.reduce((acc, office) => {
        const lorryCollection = parseFloat(financialData[office]?.['lorry collection'] || 0);
        const lorryExpenses = parseFloat(financialData[office]?.['lorry Expenses'] || 0);
        const lorryCommission = parseFloat(financialData[office]?.['lorry commission'] || 0);
        const lorryVAT = parseFloat(financialData[office]?.['lorry VAT'] || 0);
        const deficitSurplus = lorryCollection - lorryExpenses - lorryCommission - lorryVAT;
        return acc + deficitSurplus;
      }, 0).toFixed(2),
      ...offices.reduce((acc, office) => {
        const officeData = financialData[office] || { 
          'lorry collection': 0, 
          'lorry Expenses': 0, 
          'lorry commission': 0, 
          'lorry VAT': 0 
        };
        acc[office] = parseFloat(
          calculateDeficitSurplus(
            officeData['lorry collection'], 
            officeData['lorry Expenses'], 
            officeData['lorry commission'], 
            officeData['lorry VAT']
          )
        ).toFixed(2);
        return acc;
      }, {})
    },
  ];

  return (
    <div>
      <h2>Financial Reports(Lorry)</h2>
      <TextField
        label="Start Date"
        type="date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
        style={{ marginRight: '10px' }}
      />
      <TextField
        label="End Date"
        type="date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
        style={{ marginRight: '10px' }}
      />
      <Button variant="contained" color="primary" onClick={fetchFinancialData} style={{ marginRight: '10px' }}>
        Get Data
      </Button>
      <Button variant="contained" color="secondary" onClick={exportToExcel}>
        Export to Excel
      </Button>

      <DataTable
        title="Financial Report"
        columns={columns}
        data={metrics}
        pagination
        highlightOnHover
        striped
      />
    </div>
  );
};

export default LorryReports;
