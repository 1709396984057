import React, { useState } from 'react';
import axiosInstance from './axiosInstance';
import DataTable from 'react-data-table-component';
import { TextField, Button } from '@mui/material';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const FinancialReport = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [financialData, setFinancialData] = useState({});
  const offices = [
    "Nairobi", 
    "Garissa", 
    "Habaswein", 
    "Lag Boqol", 
    "Leheley", 
    "Wajir"
  ];

  const fetchFinancialData = async () => {
    try {
      const response = await axiosInstance.get(`/financials/totals/${startDate}/${endDate}`);
      setFinancialData(response.data);
    } catch (error) {
      console.error("Error fetching financial data", error);
    }
  };

  // Calculate Deficit/Surplus: Bus collection + Tickets - Bus expenses - Bus VAT - Bus commission
  const calculateDeficitSurplus = (busCollection, tickets, busExpenses, busVAT, busCommission) => {
    return parseFloat(busCollection + tickets - busExpenses - busVAT - busCommission).toFixed(2);
  };

  const exportToExcel = () => {
    const excelData = offices.map(office => {
      const officeData = financialData[office] || { 'bus collection': 0, reservation: 0, 'bus Expenses': 0, 'bus VAT': 0, 'bus commission': 0 };
      const deficitSurplus = parseFloat(calculateDeficitSurplus(
        officeData['bus collection'], 
        officeData.reservation, 
        officeData['bus Expenses'], 
        officeData['Bus VAT'], 
        officeData['bus commission']
      ));

      return {
        Office: office,
        'Bus Collection': Number(officeData['bus collection']).toFixed(2),
        Tickets: Number(officeData.reservation).toFixed(2),
        'Bus Expenses': Number(officeData['bus Expenses']).toFixed(2),
        'Bus VAT': Number(officeData['bus VAT']).toFixed(2),
        'Bus Commission': Number(officeData['bus commission']).toFixed(2),
        'Deficit/Surplus': deficitSurplus,
      };
    });

    // Create a worksheet
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    
    // Create a workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Financial Report');
    
    // Write the Excel file and trigger download
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    
    saveAs(blob, 'financial_report.xlsx');
  };

  const columns = [
    {
      name: 'Metric',
      selector: row => row.metric,
      sortable: true,
    },
    ...offices.map(office => ({
      name: office,
      selector: row => {
        const value = row[office];
        return Number(value).toFixed(2); // Ensure value is a number
      },
      sortable: true,
      conditionalCellStyles: [
        {
          when: row => row.metric === 'Deficit/Surplus' && row[office] > 0,
          style: {
            backgroundColor: 'green',
            color: 'white',
          },
        },
        {
          when: row => row.metric === 'Deficit/Surplus' && row[office] < 0,
          style: {
            backgroundColor: 'red',
            color: 'white',
          },
        },
      ],
    })),
    {
      name: 'Total',
      selector: row => {
        const value = row.total;
        return (typeof value === 'number' ? value : parseFloat(value)).toFixed(2); // Ensure value is a number
      },
      sortable: true,
    }
  ];

  const metrics = [

    { 
      metric: 'Parcel (Total)', 
      total: offices.reduce((acc, office) => acc + (financialData[office]?.parcel || 0), 0).toFixed(2),
      ...offices.reduce((acc, office) => {
        const officeData = financialData[office] || { parcel: 0 };
        acc[office] = Number(officeData.parcel || 0).toFixed(2);
        return acc;
      }, {})
    },
    { 
      metric: 'Bus Collection', 
      total: offices.reduce((acc, office) => acc + (financialData[office]?.['bus collection'] || 0), 0).toFixed(2),
      ...offices.reduce((acc, office) => {
        const officeData = financialData[office] || { 'bus collection': 0 };
        acc[office] = Number(officeData['bus collection']).toFixed(2);
        return acc;
      }, {})
    },
    { 
      metric: 'Tickets', 
      total: offices.reduce((acc, office) => acc + (financialData[office]?.reservation || 0), 0).toFixed(2),
      ...offices.reduce((acc, office) => {
        const officeData = financialData[office] || { reservation: 0 };
        acc[office] = Number(officeData.reservation).toFixed(2);
        return acc;
      }, {})
    },
    { 
      metric: 'Bus Expenses', 
      total: offices.reduce((acc, office) => acc + (financialData[office]?.['bus Expenses'] || 0), 0).toFixed(2),
      ...offices.reduce((acc, office) => {
        const officeData = financialData[office] || { 'bus Expenses': 0 };
        acc[office] = Number(officeData['bus Expenses']).toFixed(2);
        return acc;
      }, {})
    },
    { 
      metric: 'Bus VAT', 
      total: offices.reduce((acc, office) => acc + (financialData[office]?.['bus VAT'] || 0), 0).toFixed(2),
      ...offices.reduce((acc, office) => {
        const officeData = financialData[office] || { 'bus VAT': 0 };
        acc[office] = Number(officeData['bus VAT']).toFixed(2); // Ensure value is a number
        return acc;
      }, {})
    },
    { 
      metric: 'Bus Commission', 
      total: offices.reduce((acc, office) => acc + (financialData[office]?.['bus commission'] || 0), 0).toFixed(2),
      ...offices.reduce((acc, office) => {
        const officeData = financialData[office] || { 'bus commission': 0 };
        acc[office] = Number(officeData['bus commission']).toFixed(2);
        return acc;
      }, {})
    },
    { 
      metric: 'Deficit/Surplus', 
      total: offices.reduce((acc, office) => {
        const busCollection = parseFloat(financialData[office]?.['bus collection'] || 0);
        const tickets = parseFloat(financialData[office]?.reservation || 0);
        const busExpenses = parseFloat(financialData[office]?.['bus Expenses'] || 0);
        const busVAT = parseFloat(financialData[office]?.['bus VAT'] || 0);
        const busCommission = parseFloat(financialData[office]?.['bus commission'] || 0);
        const deficitSurplus = busCollection + tickets - busExpenses - busVAT - busCommission;
        return acc + deficitSurplus;
      }, 0).toFixed(2),
      ...offices.reduce((acc, office) => {
        const officeData = financialData[office] || { 'bus collection': 0, reservation: 0, 'bus Expenses': 0, 'bus VAT': 0, 'bus commission': 0 };
        acc[office] = parseFloat(calculateDeficitSurplus(
          officeData['bus collection'], 
          officeData.reservation, 
          officeData['bus Expenses'], 
          officeData['bus VAT'], 
          officeData['bus commission']
        )).toFixed(2);
        return acc;
      }, {})
    }
  ];

  return (
    <div>
      <h2>Financial Report (Bus)</h2>
      <TextField
        label="Start Date"
        type="date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
        style={{ marginRight: '10px' }}
      />
      <TextField
        label="End Date"
        type="date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
        style={{ marginRight: '10px' }}
      />
      <Button variant="contained" color="primary" onClick={fetchFinancialData} style={{ marginRight: '10px' }}>
        Get Data
      </Button>
      <Button variant="contained" color="secondary" onClick={exportToExcel}>
        Export to Excel
      </Button>

      <DataTable
        title="Financial Report"
        columns={columns}
        data={metrics}
        pagination
        highlightOnHover
        customStyles={{
          headCells: {
            style: {
              backgroundColor: 'blue',
              color: 'white',
            },
          },
          cells: {
            style: {
              backgroundColor: 'white',
              color: 'black',
            },
          },
        }}
      />
    </div>
  );
};

export default FinancialReport;
