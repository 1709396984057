import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
} from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import axiosInstance from './axiosInstance';
import logo from '../../images/dream.PNG';

const CorporateManager = () => {
  const [corporates, setCorporates] = useState([]);
  const [newCorporateName, setNewCorporateName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedCorporate, setSelectedCorporate] = useState('');
  const [invoices, setInvoices] = useState([]); 
 

  // Fetch all corporates
  useEffect(() => {
    fetchCorporates();
  }, []);

  const fetchCorporates = async () => {
    try {
      const response = await axiosInstance.get('/customer/findall');
      setCorporates(response.data);
    } catch (error) {
      console.error('Error fetching corporates:', error);
    }
  };

  const handleAddCorporate = async () => {
    if (!newCorporateName || !email || !phoneNumber || !contactPerson) return;
  
    try {
      await axiosInstance.post('/customer/add', {
        name: newCorporateName,
        email,
        phoneNumber,
        contactPerson
      });
      // Reset fields after successful addition
      setNewCorporateName('');
      setEmail('');
      setPhoneNumber('');
      setContactPerson('');
      fetchCorporates(); // Refresh the list after adding
      setOpenDialog(false); // Close the dialog
    } catch (error) {
      console.error('Error adding corporate:', error);
    }
  };
  

  const handleFetchInvoices = async () => {
    if (!selectedCorporate || !startDate || !endDate) {
      alert('Please select a corporate name and specify a date range.');
      return;
    }

    try {
      const invoiceData = await axiosInstance.get(`/parcel/invoice/${selectedCorporate}/${startDate}/${endDate}`);
      setInvoices(invoiceData.data); // Store invoice data in state
      console.log('Invoices:', invoiceData.data);
    } catch (error) {
      console.error('Error fetching invoices:', error);
    }
  };

  // Calculate the total amount for the invoices
  const totalAmount = invoices.reduce((total, invoice) => total + (invoice.cashAmount || 0), 0);

  const downloadPDF = () => {
    // Create a new jsPDF instance with landscape orientation
    const doc = new jsPDF('landscape');
    const pageWidth = doc.internal.pageSize.getWidth();
    
    // Organization Logo and Header
    const logoWidth = 30;
    const logoX = (pageWidth - logoWidth) / 2;
    
    // Add the organization logo at the top center
    doc.addImage(logo, 'PNG', logoX, 10, logoWidth, 20);
  
    // Organization Name and Address
    const organizationName = 'Dream C Ltd';
    doc.setFontSize(16);
    doc.setFont('Helvetica', 'bold');
    doc.text(organizationName, 148.5, 35, { align: 'center' });
    
    const organizationAddress = 'P.O.BOX 94-70200 Wajir, Kenya';
    const organizationPhone = 'Office Number-Nairobi: +254 769200300';
    const organizationWajir = 'Office Number-Wajir: +254 769200400';
    doc.setFontSize(14);
    doc.setFont('Helvetica', 'normal');
    doc.text(organizationAddress, 148.5, 42, { align: 'center' });
    doc.text(organizationPhone, 148.5, 47, { align: 'center' });
    doc.text(organizationWajir, 148.5, 53, { align: 'center' });
  
    // Add the word "Invoice" with larger, bold font below the logo
    doc.setFontSize(32);
    doc.setFont('Helvetica', 'bold');
    doc.text('Invoice', 148.5, 65, { align: 'center' });
  
    // Corporate Name section with smaller font below the "Invoice" title
    doc.setFontSize(16);
    doc.setFont('Helvetica', 'bold');
    doc.text(`Customer Name: ${selectedCorporate}`, 148.5, 73, { align: 'center' });
  
    // Table Headers
    const tableColumn = [
      'Source', 'Destination', 'Sender Name', 'Receiver Name',
      'Sender Phone', 'Receiver Phone', 'Book Date', 'Weight', 
      'Tracking Code', 'Items Count', 'Package Type', 'Amount'
    ];
    const tableRows = [];
    
    let totalAmount = 0; // Variable to hold the total amount
  
    // Populate table rows with invoice data
    invoices.forEach((invoice) => {
      const invoiceData = [
        invoice.source,
        invoice.destination,
        invoice.senderName,
        invoice.receiverName,
        invoice.senderPhone,
        invoice.receiverPhone,
        invoice.bookDate,
        invoice.parcelWeight,
        invoice.trackingCode,
        invoice.itemsCount,
        invoice.packageType,
        invoice.cashAmount,
      ];
      totalAmount += parseFloat(invoice.cashAmount); 
      tableRows.push(invoiceData);
    });
  
    // Add a row for the total amount
    const totalRow = ['', '', '', '', '', '', '', '', '', '', 'Total Amount', totalAmount.toFixed(2)];
    tableRows.push(totalRow);
  
    // Add the table to the PDF, positioning it a little lower
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 78, 
      styles: { fontSize: 10 }, 
    });
  
    // Additional Fields at the Bottom
    const username = localStorage.getItem('username'); 
    const dateGenerated = new Date().toLocaleDateString();
  
    // Generated By Section
    doc.setFontSize(12);
    doc.setFont('Helvetica', 'normal');
    doc.text(`Generated By: ${username}`, 14, doc.internal.pageSize.getHeight() - 40);
    doc.text(`Date Generated: ${dateGenerated}`, 14, doc.internal.pageSize.getHeight() - 30);
  
    // Comments Section
    doc.text("Comments:", 80, doc.internal.pageSize.getHeight() - 40);
    doc.line(110, doc.internal.pageSize.getHeight() - 40, pageWidth - 20, doc.internal.pageSize.getHeight() - 40);
  
    // Signature Field
    doc.text("Authorized Signature:", 14, doc.internal.pageSize.getHeight() - 20);
    doc.line(60, doc.internal.pageSize.getHeight() - 20, 140, doc.internal.pageSize.getHeight() - 20);
  
    // Organization Stamp Field
    doc.text("Organization Stamp:", 160, doc.internal.pageSize.getHeight() - 20);
    doc.line(210, doc.internal.pageSize.getHeight() - 20, 290, doc.internal.pageSize.getHeight() - 20);
  
    // Save the PDF using the corporate name as filename
    doc.save(`${selectedCorporate}_Invoices.pdf`);
  };
  
  
  
  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Corporate Management
      </Typography>
      <Button variant="contained" onClick={() => setOpenDialog(true)}>
        Add Corporate
      </Button>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Add Corporate</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Corporate Name"
            type="text"
            fullWidth
            variant="outlined"
            value={newCorporateName}
            onChange={(e) => setNewCorporateName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Phone Number"
            type="tel"
            fullWidth
            variant="outlined"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Contact Person"
            type="text"
            fullWidth
            variant="outlined"
            value={contactPerson}
            onChange={(e) => setContactPerson(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleAddCorporate}>Add</Button>
        </DialogActions>
      </Dialog>


      <Box sx={{ my: 2 }}>
        <TextField
          label="Start Date"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <TextField
          label="End Date"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <Button variant="outlined" onClick={handleFetchInvoices}>
          Fetch Invoices
        </Button>
        <Button variant="outlined" onClick={downloadPDF} disabled={!invoices.length}>
          Download PDF
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Select</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Corporate Name</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Email</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Phone Number</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Contact Person</TableCell>
              <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {corporates.map((corporate) => (
              <TableRow key={corporate.id}>
                <TableCell>
                  <Radio
                    checked={selectedCorporate === corporate.name}
                    onChange={() => setSelectedCorporate(corporate.name)}
                  />
                </TableCell>
                <TableCell>{corporate.name}</TableCell>
                <TableCell>{corporate.email}</TableCell>
                <TableCell>{corporate.phoneNumber}</TableCell>
                <TableCell>{corporate.contactPerson}</TableCell>
                <TableCell>
                  <Button variant="outlined" onClick={() => handleFetchInvoices(corporate.name)}>
                    View Invoices
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {invoices.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Invoice List for {selectedCorporate}:</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Source</TableCell>
                  <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Destination</TableCell>
                  <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Sender Name</TableCell>
                  <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Receiver Name</TableCell>
                  <TableCell sx={{ backgroundColor: '#1976d2', color: '#fff' }}>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.map((invoice) => (
                  <TableRow key={invoice.id}>
                    <TableCell>{invoice.source}</TableCell>
                    <TableCell>{invoice.destination}</TableCell>
                    <TableCell>{invoice.senderName}</TableCell>
                    <TableCell>{invoice.receiverName}</TableCell>
                    <TableCell>{invoice.cashAmount}</TableCell>
                  </TableRow>
                ))}
                {/* Total Amount Row */}
                <TableRow>
                  <TableCell colSpan={4} align="right">
                    <strong>Total Amount</strong>
                  </TableCell>
                  <TableCell>
                    <strong>{totalAmount}</strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default CorporateManager;